import React from 'react';
import Cards from 'components/Core/Cards';
import CardsGroup from 'components/Core/Cards/CardsGroup';
import WistiaVideo from 'components/Core/WistiaVideo';

import { ROIResultHowContainer } from '../styles';

const ROIResultHow = ({ background, title, cards, ...otherProps }) => {
  const video = 'https://servicetitan-1.wistia.com/medias/5aep05cqp5';

  return (
    <ROIResultHowContainer background={background} {...otherProps}>
      <div className="section-roi-result-how--container">
        <h2 className="section-roi-result-how--title font-h1">{title}</h2>
        <div className="section-roi-result-how--video-content">
          <div className="section-roi-result-how--video-container">
            <WistiaVideo url={video} size="Medium" />
          </div>
        </div>

        <div className="section-roi-result-how--main">
          {cards?.length > 0 && (
            <CardsGroup cols={3}>
              {cards.map((item, index) => (
                <Cards
                  type="Icon"
                  iconImage={item.icon}
                  title={item.title}
                  description={item.description?.description}
                  key={index}
                />
              ))}
            </CardsGroup>
          )}
        </div>
      </div>
    </ROIResultHowContainer>
  );
};

export default ROIResultHow;
