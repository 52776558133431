import { graphql, useStaticQuery } from 'gatsby';

const useBusinessCalcResultImages = () => {
  const { allContentfulImage } = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          contentful_id: {
            in: [
              "2irSaQDVpSPIrSWj8pLqX8" #bg-main-01-calc
              "42rFAuBKgiJc3CANinKl9M" #Business Calc Clock
              "7F5cG34BpsVdT4iN0Kq9XM" #biz calc social share
            ]
          }
        }
      ) {
        edges {
          node {
            internalName
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);

  return allContentfulImage?.edges;
};

export default useBusinessCalcResultImages;
