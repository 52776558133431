import React from 'react';
import { graphql, navigate } from 'gatsby';
import queryString from 'query-string';
import ToolboxLayout from 'layouts/toolboxLayout';
import BCResultsHero from 'components/Tools/BusinessCalculator/ResultsHero';
import ROIResultHow from 'components/Tools/ROICalculator/ResultHow';
import DemoFormSection from 'components/Sections/DemoFormSection';
import useBusinessCalcResultImages from 'hooks/useBusinessCalcResultImages';
import { convertParamsToToolResult } from '../../../utils/urlHelper';

const BusinessCalcResultPage = (props) => {
  const pageImages = useBusinessCalcResultImages();
  const backImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'bg-main-01-calc')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const socialShareImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'biz calc social share')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const url = props.location.search;
  const params = queryString.parse(url);
  let results = {
    grossProfit: null,
    ebitda: null,
    ebitdaPercent: null,
    ebitdaMultiplier: null,
    maximum: null,
    upperQuartile: null,
    medianEstimate: null,
    lowerQuartile: null,
    minimum: null,
    existingUser: null,
    newCompany: null,
    totalIncrease: null,
  };

  results = convertParamsToToolResult(results, params);

  const incompleteParams = Object.values(results).filter((elem) => elem === null);

  if (typeof window !== 'undefined' && incompleteParams.length > 0) {
    navigate(`/${props.pageContext.pagePrefix}`);
    return null;
  }

  return (
    <ToolboxLayout
      contentfulSeo={{
        ...props.data.contentfulSeo,
        pageUrl: 'tools/service-business-valuation-calculator-results',
        pageTitle: 'Service Business Valuation Calculator Result | ServiceTitan',
        ogTagImage: { socialShareImg },
      }}
      layout="toolbox"
      {...props}
    >
      <BCResultsHero backgroundImg={backImg} results={results} />
      <div>
        {results.existingUser === 'No' && (
          <>
            <ROIResultHow
              background="var(--skyline-1)"
              title="How can ServiceTitan increase revenue?"
            />
            <DemoFormSection
              backgroundColor="var(--dark)"
              formTitle="Let’s get started."
              description="Explore why 100,000+ contractors trust Service Titan to run their business."
              title="Book a personalized demo today."
            />
          </>
        )}
      </div>
    </ToolboxLayout>
  );
};

export default BusinessCalcResultPage;

export const BusinessCalcResultPageQuery = graphql`
  query BusinessCalcResultPageQuery($internalName: String!) {
    contentfulSeo(internalName: { eq: $internalName }) {
      ...Seo
    }
    socialShareImg: contentfulImage(contentful_id: { eq: "biz calc social share" }) {
      ...WebsiteImage
    }
  }
`;
