import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import { STIcon } from 'components/Brand/Icons/STIcon';
import { globalHistory } from '@reach/router';
import copy from 'clipboard-copy';
import FormLabelWithField from 'components/Core/FormLabelWithField';
import Button from 'components/Core/Button';
import useBusinessCalcResultImages from 'hooks/useBusinessCalcResultImages';
import SocialSharing from 'components/SocialSharing';
import { ResultHeroContainer } from '../styles';
import { fnum, numberWithCommas } from '../bcUtils';

const BCResultsHero = ({ results = {}, ...otherProps }) => {
  const pageImages = useBusinessCalcResultImages();
  const backImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'bg-main-01-calc')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const clockImage =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'Business Calc Clock')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const socialShareImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === 'biz calc social share')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const socialTitle = `My business was valued at $${numberWithCommas(
    fnum(results.medianEstimate),
  )}`;

  return (
    <ResultHeroContainer
      existingUser={results && results.existingUser}
      backgroundImg={backImg}
      {...otherProps}
    >
      <div className="section-bc-results-hero--wrapper">
        <div className="section-bc-results-hero--container">
          <div className="section-bc-results-hero--first-section">
            <div className="section-bc-results-hero--first-left-side">
              <h2 className="section-bc-results-hero--title">Estimated company value:</h2>
              <h2
                data-testid="lowerQuartile_upperQuartile"
                className="section-bc-results-hero--estimate"
              >
                {results?.lowerQuartile < 0 && '-'}$
                {numberWithCommas(Math.abs(parseFloat(results?.lowerQuartile).toFixed(0)))} -{' '}
                {results?.lowerQuartile < 0 && '-'}$
                {numberWithCommas(Math.abs(parseFloat(results?.upperQuartile).toFixed(0)))}
              </h2>
              <div className="section-bc-results-hero--graph-container">
                <div className="section-bc-results-hero--graph-vertical-line" />
                <div className="section-bc-results-hero--graph">
                  <div className="section-bc-results-hero--graph-label">
                    <p>Lowest</p>
                    <p>Highest</p>
                  </div>
                  <div className="section-bc-results-hero--main-graph">
                    <div className="section-bc-results-hero--main-graph-main" />
                  </div>
                </div>
                <div className="section-bc-results-hero--graph-label">
                  <p data-testid="minimum">{numberWithCommas(fnum(results?.minimum))}</p>
                  <p data-testid="medianEstimate">
                    {numberWithCommas(fnum(results?.medianEstimate))}
                  </p>
                  <p data-testid="maximum">{numberWithCommas(fnum(results?.maximum))}</p>
                </div>
              </div>
              <div className="section-bc-results-hero-disclaimer">
                <p>
                  Disclaimer Statement: *Estimated company value is estimated in good faith and are
                  solely meant for generic, informative purposes
                </p>
              </div>
            </div>
            <div className="section-bc-results-hero--first-right-side">
              <div className="section-bc-results-hero--metrics">
                <h2 className="section-bc-results-hero--metrics-title">Company metrics</h2>
                <HyperLink
                  data-position="Body - Retake Form CTA"
                  href="/tools/service-business-valuation-calculator"
                >
                  <p className="section-bc-results-hero--metrics-retake">
                    <STIcon type="icon-reload-outline" />
                    Retake
                  </p>
                </HyperLink>
              </div>
              <div className="section-bc-results-hero--metrics-card-container">
                <div className="section-bc-results-hero--metrics-card metrics-card-red">
                  <p className="section-bc-results-hero--metrics-card-title">Gross Profit</p>
                  <h2
                    data-testid="grossProfit"
                    className="section-bc-results-hero--metrics-card-value"
                  >
                    {results?.grossProfit < 0 && '-'}$
                    {numberWithCommas(Math.abs(parseFloat(results?.grossProfit).toFixed(3)))}
                  </h2>
                </div>
                <div className="section-bc-results-hero--metrics-card">
                  <p className="section-bc-results-hero--metrics-card-title">EBITDA</p>
                  <h2 data-testid="ebitda" className="section-bc-results-hero--metrics-card-value">
                    {results?.ebitda < 0 && '-'}$
                    {numberWithCommas(Math.abs(parseFloat(results?.ebitda).toFixed(3)))}
                  </h2>
                </div>
                <div className="section-bc-results-hero--metrics-card">
                  <p className="section-bc-results-hero--metrics-card-title">EBITDA %</p>
                  <h2
                    data-testid="ebitdaPercent"
                    className="section-bc-results-hero--metrics-card-value"
                  >
                    {numberWithCommas(results?.ebitdaPercent)}%
                  </h2>
                </div>
                {results && results.existingUser === 'No' && (
                  <div
                    data-testid="existinguser_newCompany"
                    className="section-bc-results-hero--metrics-card"
                  >
                    <p className="section-bc-results-hero--metrics-card-title">
                      Value using <br />
                      ServiceTitan
                    </p>
                    <h2
                      data-testid="newCompany"
                      className="section-bc-results-hero--metrics-card-value"
                    >
                      ${numberWithCommas(parseFloat(results.newCompany).toFixed(0))}
                    </h2>
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="section-bc-results-hero--second-section">
            <h2 className="section-bc-results-hero--share-title">Share this report:</h2>
            <div className="section-bc-results-hero--share-container">
              <SocialSharing
                shareText={socialTitle}
                pageUrl="servicetitan.com/tools/service-business-valuation-calculator"
                description="In just a few clicks, estimate the current value of your business. Check it out now!"
              />

              <div className="section-bc-results-hero--share-splitter" />
              <Button
                className="section-bc-results-hero--share-copy-btn"
                onClick={() => copy(globalHistory.location.href)}
                shadow={false}
              >
                Copy URL
              </Button>
            </div>
          </div>

          {results?.existingUser === 'No' && (
            <div
              data-testid="existinguser_content"
              className="section-bc-results-hero--third-section"
            >
              <div className="section-bc-results-hero--third-left-side">
                <div className="section-bc-results-hero--demo-container">
                  <h2 className="section-bc-results-hero--demo-title">
                    Transform your business with all-in-one system.
                  </h2>
                  <p className="section-bc-results-hero--demo-description">
                    Explore why 100,000+ contractors trust Service Titan to run their business.
                  </p>

                  <Button
                    data-position="Body - Get Demo CTA"
                    to="/demo"
                    className="section-bc-results-hero--get-demo-btn"
                    shadow={false}
                  >
                    Get Demo
                  </Button>
                </div>
              </div>
              <div className="section-bc-results-hero--third-right-side">
                <div className="section-bc-results-hero--third-right-title">
                  <FormLabelWithField
                    className="section-bc-results-hero--third-right-title-label"
                    label="Projected Valuation with ServiceTitan"
                    isRequired={false}
                    helperText="Calculated using average revenue increase for a ServiceTitan user in 1 year. Individual results may vary."
                  />
                  <p className="section-bc-results-hero--based-on-average">
                    **based on average ServiceTitan customer results.
                  </p>
                </div>
                <div className="section-bc-results-hero--third-value">
                  <div className="section-bc-results-hero--third-value-text">
                    <p className="section-bc-results-hero--third-value-title">New Company Value</p>

                    <h2
                      data-testid="newCustomer_newCompany"
                      className="section-bc-results-hero--third-value-value"
                    >
                      ${numberWithCommas(parseFloat(results?.newCompany).toFixed(0))}
                    </h2>
                    <p className="section-bc-results-hero--third-value-title">Total Increase</p>
                    <h2
                      data-testid="newCustomer_totalIncrease"
                      className="section-bc-results-hero--third-value-value"
                    >
                      {results?.totalIncrease >= 0 ? '+' : '-'}$
                      {numberWithCommas(Math.abs(parseFloat(results?.totalIncrease).toFixed(0)))}
                    </h2>
                  </div>
                  <div className="section-bc-results-hero--third-value-image">
                    <div className="section-bc-results-hero--third-clock-value-container">
                      <h2 className="section-bc-results-hero--third-clock-value">+24%</h2>
                      <p className="section-bc-results-hero--third-clock-description">
                        Percent Increase
                      </p>
                    </div>
                    <img className="section-bc-results-hero--third-clock-image" src={clockImage} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ResultHeroContainer>
  );
};

export default BCResultsHero;
